body,
p,
span {
  font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: 'Rubik', sans-serif;
}

:root {
  --primary-color: #00c4cc;
  --secondary-color: #7c2ae8;
}

.finix-text h6 {
  color: var(--primary-color);
  font-weight: 400;
  font-size: 17px;
}

.finix-text h2 {
  font-weight: 400;
  font-size: 35px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.finix-text p {
  color: #606060;
  font-size: 15px;
  line-height: 1.8rem;
}

.why-choose-us {
  margin-top: 90px;
}

.why-choose-us .finix-text h2 {
  font-weight: 500;
}

/* .why-choose-us .ct-btn {
  display: inline-block;
  width: 85px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  border-radius: 50%;
  font-size: 60px;
  background: #fff;
  position: absolute;
  top: 77%;
  right: 43%;
}


.why-choose-us .ct-btn::before {
  content: "";
  height: 85px;
  width: 85px;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--primary-color);
  border-radius: 50%;
  animation: choose 1.6s ease-out infinite;
} */

@keyframes choose {

  0%,
  30% {
    transform: scale(0);
    opacity: 1;
  }

  50% {
    transform: scale(1.5);
    opacity: .7;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.why-choose-us .ct-btn a {
  color: var(--primary-color);
}

.why-choose-us .feature-box {
  background: #fff;
  padding-bottom: 30px;
  padding-top: 30px;

  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  transition: .4s;
}

.why-choose-us .feature-box:hover {
  transform: translateY(-10px);
}

.why-choose-us .feature-box:hover .fbc-btn {
  background: var(--secondary-color);
  color: #fff;

}

.why-choose-us .feature-box::before {
  content: "";
  position: absolute;
  top: -115px;
  left: 0;
  right: 0;
  margin: auto;
  width: 90%;
  height: 70%;
  background: #dafcff;
  border-radius: 50%;
}

.why-choose-us .fbc-btn {
  display: inline-block;
  height: 80px;
  width: 90px;
  line-height: 78px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  color: var(--primary-color);
  position: relative;
  transition: .4s;
}

.active-feature {
  margin-top: -30px;
}

.why-choose-us .finix-text {
  position: relative;
}

.wcuIconStyle {
  width: 100%;
  height: 75%;

}


/* Whatsapp Number */

.whatsapp-btn {
  display: inline-block;
  width: 85px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  border-radius: 50%;
  font-size: 60px;
  position: absolute;
  color: white;

}

.whatsapp-btn::before {
  content: "";
  height: 85px;
  width: 85px;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--primary-color);
  border-radius: 50%;
  animation: choose 1.6s ease-out infinite;
}


.whatsappIcon-btn {
  display: inline-block;
  height: 90px;
  width: 90px;
  line-height: 78px;
  background: #25d366;
  border-radius: 50%;
  text-align: center;
  color: white;
  position: relative;
  transition: .4s;
}

.WhatsappNumber {
  position: fixed;
  top: 85%;
  right: 9%;
  z-index: 1000;
}

/* Phone Number */


.Phone-btn {
  display: inline-block;
  width: 85px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  border-radius: 50%;
  font-size: 60px;
  position: absolute;

}

.Phone-btn::before {
  content: "";
  height: 85px;
  width: 85px;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--primary-color);
  border-radius: 50%;
  animation: choose 1.6s ease-out infinite;
}


.PhoneIcon-btn {
  display: inline-block;
  height: 90px;
  width: 90px;
  line-height: 78px;
  background: var(--primary-color);
  border-radius: 50%;
  text-align: center;
  color: white;
  position: relative;
  transition: .4s;
}

.PhoneNumber {
  position: fixed;
  top: 85%;
  left: 3%;
  z-index: 1000;
}

.whatsapp-btn a {
  color: white !important;
}

.Phone-btn a {
  color: white !important;
}

@media (max-width: 992px) {
  .Phone-btn {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 40px;
  }

  .Phone-btn::before {
    height: 50px;
    width: 50px;
    left: 4px;
    top: 4px;

  }

  .PhoneIcon-btn {
    height: 60px;
    width: 60px;
    line-height: 55px;
  }

  .PhoneNumber {
    top: 89%;
    left: 7%;
  }

  .whatsapp-btn {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 40px;
  }

  .whatsapp-btn::before {
    height: 50px;
    width: 50px;
    left: 4px;
    top: 4px;
  }

  .whatsappIcon-btn {
    height: 60px;
    width: 60px;
    line-height: 55px;
  }

  .WhatsappNumber {
    top: 89%;
    right: 25%;
  }

}