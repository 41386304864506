.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a {
  text-decoration: none !important;
  /* color: black !important; */
}

:root {
  --primary-color: #00c4cc;
  --secondary-color: #7c2ae8;
  --padding-size: 1.5rem;
  --bs-table-bg: #FAB217 !important;
}

.bgc-primary {
  background-color: var(--primary-color);
}

.bgc-secondary {
  background-color: var(--secondary-color);
}

.textCol-primary {
  color: var(--primary-color);
}

.textCol-secondary {
  color: var(--secondary-color);

}


body {
  font-family: 'Montserrat', sans-serif !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

li {
  /* color: black; */
  /* margin-left: 25px; */
  text-align: center;
  font-size: 18px;
  text-align: justify !important;

}

.nav-link:hover {
  background-color: var(--secondary-color);
  color: #fff !important;
  width: fit-content !important;
}

.footerMenu {
  list-style-type: none;
  line-height: 50px;
}

.footerMenuCity {
  line-height: 30px;

}

.footerMenu a {
  color: black !important;
}

.footerMenu:hover a,
.footerMenuCity:hover  {
  background-color: var(--secondary-color);
  color: #fff !important;
  padding: 2%;
}

li.nav-item:hover {
  width: fit-content !important;
}

.navbar-collapse {
  margin-left: 25%;
}

.MenuButton {
  background-color: var(--primary-color);
  color: white !important;
  border: none !important;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.MenuButton a {
  color: white !important;

}

.MenuButton:hover {
  background-color: var(--secondary-color);
  color: #fff !important;
}

.sticky-top {
  top: 0;
  /* Keeps the navbar at the top */
  z-index: 1030;
  /* Ensures the navbar is above other content */
  background-color: white;
}

/* Ensure text-justify works properly */
.text-justify {
  text-align: justify;
}

@media only screen and (max-width: 991px) {
  .navbar-collapse {
    margin-left: 0%;
  }

}

.bannerContent1 {
  text-align: start;
}

.formBo {
  border: 2px solid black;
  border-radius: 3%;

}

.formMain {
  background-color: var(--primary-color);
}

.SEOTable th,
.SEOTable td {
  background-color: white !important;
  color: #000;
  padding: 10px;
  text-align: center;
}

th,
td {
  background-color: #FAB217 !important;
}

/* Apply to all table cells */
table {
  width: 100%;
  table-layout: fixed;
}

th,
td {
  word-wrap: break-word;
  word-break: break-word;
  padding: 10px;
  overflow-wrap: break-word;
}

table td {
  white-space: normal;
}

.prImg>div>img {
  width: 426px;
  height: 369px;
}

.bgimg {
  background-image: url(./img/homebannerbg.jpg);
  position: relative;

}

.bgimg::before {
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(247, 193, 3, 0.9);
  /* Semi-transparent */
  z-index: 1;
}

.homecontent>img {
  width: 250px;
}

.homecontent>button {
  border-radius: .375rem;
  background-color: red;
  color: white;
  font-weight: 500;
  font-size: 20px;
}

.homeformbt>button {
  border-radius: 20px;
  color: white;
  font-weight: 500;
  font-size: 20px;
  width: 48%;
  color: black;
  border-radius: 20px;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.formtoggle {
  border-radius: 20px;
  color: white;
  font-weight: 500;
  font-size: 20px;
  width: 48% !important;
  color: black;
  border-radius: 20px;
  border: none;
  padding: 10px;
  cursor: pointer;
  background-color: #f6f6f6;
  margin-top: 2%;

}

.findcabbtn {
  border-radius: 20px;
  color: white;
  font-weight: 500;
  font-size: 20px;
  width: 98% !important;
  color: black;
  border-radius: 20px;
  border: none;
  padding: 10px;
  cursor: pointer;
  background-color: var(--secondary-color);
  color: white;

}

.findcabbtn:hover {
  background-color: var(--secondary-color);
  color: white;

}

.formtoggle>.radiocls:checked {
  background-color: var(--beercl);
}


.homeformbt>button.active {
  background-color: var(--beercl);

  /* Active button color */
  color: black;
  /* Text color for active button */
}

button:hover {
  background-color: var(--beercl);
}

/* .homecontentform {
  text-align: center;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;

} */

.homecontentform {
  font-size: 1.2rem !important;
  font-weight: 600;
}



/* Form Ride with Ease */
.rwe {
  background-color: var(--beercl);
  font-weight: 500;
  padding: 10px 15px;
  text-align: start;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  font-size: 18px;
  color: white !important;

}

.carSelectHeading {
  color: white !important;
}

.homecontent {
  text-align: justify;
  color: white;
}

.form-control {
  margin-bottom: 10px !important;
  padding-left: 15px !important;
  padding: 7px !important;
  line-height: 2px !important;
  height: 54px !important;
  border-radius: 20px !important;
  padding-left: 25px !important;
  padding-right: 12% !important
}


.homeforminput>div {
  width: 100%;
}

.homeforminput>div>select {
  margin-bottom: 25px;
  padding-left: 15px;
  padding: 7px;
}

.input-group {
  position: relative;
}

.form-control {
  width: 100% !important;
}

.input-icon {
  margin-left: -30px;
  /* Adjust to overlap with the input */
  pointer-events: none;
  position: absolute;
  top: 14px;
  right: 36px;
  font-size: 25px;
  /* Prevents interference with input */
}

.FaLocationArrow {
  color: red;
}

.numcodedis {
  border-radius: 20px;
  color: white;
  font-weight: 500;
  font-size: 20px;
  color: black;
  border: none;
  cursor: pointer;
  max-width: fit-content;
  left: 25px;
  top: 10px;
}

/* .mbnumd::placeholder {
  padding-left: 15%;
} */

.mbnumd {
  padding-left: 8% !important;
}


.datepickclsmain {
  width: 100% !important;
  gap: 2%;
}

.datepickcls {
  width: 100% !important;
}

/* .datepickcls {
  width: 100%;
  margin-bottom: 10px;
  padding-left: 15px;
  line-height: 1.5px;
  border-radius: 20px;
} */


.datepickcls>div>input {
  width: 100%;
  margin-bottom: 10px;

  height: 54px;
  border-radius: 20px;
  padding-left: 25px;
}

.FaCalendarcls {
  right: 25px !important;
  background-color: white;

}



.custom-datepicker {
  width: 100% !important;
  margin-bottom: 10px !important;
  padding: 7px !important;
  height: 54px !important;
  border-radius: 25px !important;
  padding-left: 17px !important;
  border: 1px solid #dee2e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.custom-datepicker::-webkit-calendar-picker-indicator {
  /* padding-left: 2%; */
  width: 20%;
  border-radius: 50%;
  top: 14px;
  left: 10px;
  font-size: 25px;

}

/* Radio Style */
input[type="radio"] {
  display: none;
}


.custom-radio {
  width: 20px;
  height: 20px;
  background-color: var(--secondary-color);
  border: 2px solid #ccc;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: pointer;
  top: 3px;
  left: -3px;
  ;
}


input[type="radio"]:checked+.custom-radio {
  background-color: var(--secondary-color);
  width: 22px;
  height: 22px;
  border: 2px solid var(--secondary-color);
  border-radius: 50%;
  top: 5px;
  left: -3px;
  ;
}

/* Optional: Create a pseudo-element for the check indicator */
.custom-radio::after {
  content: '';
  width: 13px;
  height: 13px;
  background-color: var(--primary-color);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

/* Show the inner dot when checked */
input[type="radio"]:checked+.custom-radio::after {
  display: block;

  /* Show the dot when checked */
}

/* Error Mesage */
.errorMes {
  text-align: start;
  padding-left: 29px;
  color: rgb(220, 53, 69);
  opacity: 1;
  font-weight: 500;
}

.errorMesdt {
  text-align: start;
  padding-left: 25px;
  color: rgb(220, 53, 69);
  opacity: 1;
  font-weight: 500;
}

/* Select Car style */
.selectCarcls {
  padding: 2%;
  margin: 1px;
  border: 1px solid black;
  width: 22% !important;
  text-align: center !important;
  color: white !important;
}

.selectCarImg {
  width: 100%;
}

th {
  text-align: start !important;
  /* padding-left: 10% !important; */
}

td {
  text-align: start !important;
  /* width: 61% !important; */
}

/* .Previousbtn {
  border-radius: 20px;
  color: white;
  font-weight: 500;
  font-size: 20px;
  width: 98% !important;
  color: black;
  border-radius: 20px;
  border: none;
  padding: 10px;
  cursor: pointer;
  background-color: black;
  color: white;
} */


.bannerImg {
  width: 90%;
}

@media (max-width: 768px) {

  table {
    font-size: 10px !important;
  }

  .PricePlanStyle {
    width: 100% !important;
  }
}

@media (max-width: 1300px) {
  .mbnumd {
    padding-left: 20% !important;
  }
}

@media (max-width: 992px) {
  .mbnumd {
    padding-left: 30% !important;
  }

  .input-icon {
    font-size: 15px;

  }

  .datepickclsmain {
    /* margin-left: 5% !important; */
    margin-bottom: 5% !important;
  }

  .selectCarMaincls {
    justify-content: center;
  }

  .selectCarcls {
    padding: 2%;
    margin: 1px;
    border: 1px solid black;
    width: 45.666667% !important;
    text-align: center !important;
  }


}

.parastyle {
  color: var(--secondary-color);
  font-weight: 600;
  font-size: 1.7rem;
}



.dividerMain {
  margin-left: 38%;
}

.divider {
  font-size: 30px;
  display: flex;
  align-items: center;
  width: 40%;
}

.divider::before,
.divider::after {
  flex: 1;
  content: '';
  padding: 3px;
  background-color: var(--primary-color);
  margin: 5px;
}

.PricePlanStyle {
  width: 90% !important;
  text-align: center;

}

.PricePlanStyle h3 {
  font-size: 2.125rem !important;
  line-height: 1.3em !important;
  font-weight: bolder !important;
  color: var(--secondary-color) !important;
}

.PricePlanStyle .card-title {
  color: var(--secondary-color);
}

.PricePlanImg {
  /* transform: scaleX(-1); */
  width: 90% !important;

}

.pricPlanSubcol {
  border: 0.1px dashed;
}

.iconStyle {
  color: var(--secondary-color) !important
}

.OurServiceMain {
  border: 2px solid black;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.OurServiceImg {
  width: 100%;
  height: 250px;
}

.OurServiceCardMain {
  height: 300px !important;
}

/* Our Services Flip */
.flip-card {
  width: 100%;
  height: 100%;
  /* perspective: 1000px; */
  /* Add perspective to give the flip effect depth */
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  /* Animation duration */
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
  /* Flip the card on hover */
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  /* Hide the back face when flipped */
}

.flip-card-front {
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 10px;
}

.flip-card-back {
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background: var(--primary-color); */
  color: black;
  transform: rotateY(180deg);
  /* Position the back side initially hidden */
  padding: 20px;
  border-radius: 10px;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  /* padding-top: 10px; */
}

.card-text {
  font-size: 1rem;
}

.flipContact {
  background-color: var(--primary-color);
  padding: 3%;
  border-radius: 0.372rem;
  color: white !important;
}

.flipContact:hover {
  background-color: var(--secondary-color);
  padding: 3%;
  border-radius: 0.372rem;
  color: white !important;
}

.card-img-top {
  height: 90% !important;
}



/* Look */
.lookMain {
  background-color: var(--secondary-color);
  padding: 2%;
  /* border: 1px solid var(--primary-color); */
  border-radius: .375rem;
  color: white;
}

.lookSubContent {
  background-color: var(--primary-color);
  padding: 2%;
  border-radius: .375rem;
  color: white;
}

.lookSubContent:hover {
  background-color: var(--secondary-color);
  border: 1px solid var(--primary-color);
  padding: 2%;
  border-radius: .375rem;
  color: white;
}

.bookNowStyle:hover {
  background-color: #00c4cc;
}



.PopularDropTaxiImg {
  width: 120px;
  height: 130px;
  object-fit: cover;
  border-radius: 10px;

}





.PopularDTmain {
  padding: 2%;
  padding-right: calc(var(--padding-size) / 2) !important;
  padding-left: calc(var(--padding-size) / 2) !important;
}

.pdt-content a {
  color: black;
  font-weight: 600;
}

.pdt-content p {
  color: black;
  font-weight: 600;
}


.pdtlink-btn {
  border-radius: 20px;
  background-color: var(--primary-color);
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  color: #fff;
  outline: 0;
  outline-offset: 0;
  white-space: nowrap;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.pdtlink-btn:hover {
  border-radius: 20px;
  background-color: var(--secondary-color);
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  color: #fff;
  outline: 0;
  outline-offset: 0;
  transform: scale(1.05);
}

.pdt-btn {
  border-radius: 20px;
  background-color: var(--primary-color);
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  padding: 0 25px;
  color: #fff;
  outline: 0;
  outline-offset: 0;
  white-space: nowrap;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.pdt-btn:hover {
  border-radius: 20px;
  background-color: var(--secondary-color);
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  padding: 0 25px;
  color: #fff;
  outline: 0;
  outline-offset: 0;
  transform: scale(1.05);
}

.pdt-btn a {
  color: #fff !important;
}


.offer-btn {
  width: 35%;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  background-color: var(--primary-color);
}

.offer-btn:hover {
  color: white;
  background-color: var(--secondary-color);
}

.scrolling-text {
  white-space: nowrap;
  display: inline-block;
  animation: scroll 10s linear infinite;
  text-align: center;
  width: 100%;
  font-size: 1.2rem;
}

.img-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: all .25s ease;
}

.heading {
  text-align: center;
  color: #454343;
  font-size: 30px;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
}

.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
  margin-bottom: 20px !important;
  transition: all 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
}

.card-content {
  padding: 15px;
  font-size: 14px;
}

.FamousTextList {
  font-size: 14px;
  text-align: start !important;
  line-height: 30px !important;
}


.selectedImg {
  background-color: var(--secondary-color) !important;
  color: white !important;
}

@media (max-width: 1200px) {
  .offer-btn {
    width: 75%;
    padding: 0px;

  }

  .logoStyle {
    width: 50%;
  }

  .PopularDropTaxiImg {
    height: 200px;
  }

  .FamousTextList {
    margin-left: 0px !important;
  }

}

/* Container to make the iframe responsive */
.map-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
}

/* Make the iframe fill the container */
.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 692px;
  border: 0;
}

/* For smaller screens (Mobile devices) */
@media (max-width: 768px) {
  .map-container {
    padding-bottom: 75%;
    /* Adjust for a different aspect ratio */
  }
}

/* For larger screens (Tablets and up) */
@media (min-width: 768px) {
  .map-container {
    padding-bottom: 56.25%;
    /* Standard aspect ratio */
  }
}


.imageBox {
  position: relative;
  float: left;
}

.imageBox .hoverImg {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

.imageBox:hover .hoverImg {
  display: block;
  width: 50%;
}

.imageBox:hover .hoverImg {
  display: block;
  width: 50%;

}